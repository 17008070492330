import { determineBaseUrlAndPerformApiCall } from '../../interceptor/interceptor';
import { ORDERS_LIST_API } from '../../constants/constants';
import { fetchCreateOrderSuccess, fetchCreateOrderFailure } from './reducer';
import { toast } from "react-toastify";

const updateOrderStatus = (state) => ({
    type: 'UPDATE_ORDER_STATUS',
    payload: { state },
});

export const CreateOrder = (jsonObj, setOrderID, setStatus,setTaxAmount,setTotalAmountWithTax, setCGST, setSGST) => async (dispatch) => {
    try {
        const response = await determineBaseUrlAndPerformApiCall(ORDERS_LIST_API, 'POST', jsonObj);
        if (!response.error) {
            dispatch(fetchCreateOrderSuccess(response));
            dispatch(updateOrderStatus(response.state));
            setStatus("Acknowledged")
            setOrderID(response.id)
            setTaxAmount(response?.orderTotalPrice[0]?.price?.taxIncludedAmount?.value - response?.orderTotalPrice[0]?.price?.dutyFreeAmount?.value)
            setTotalAmountWithTax(response?.orderTotalPrice[0]?.price?.taxIncludedAmount?.value)
            setCGST(response?.orderTotalPrice[0]?.price?.countryGST)
            setSGST(response?.orderTotalPrice[0]?.price?.stateGST)
            toast.success("Order created successfully!");
        } else {
            dispatch(fetchCreateOrderFailure(response.error.message));
            toast.error(`Failed to create order: ${response.error.message}`);
        }
    } catch (error) {
        dispatch(fetchCreateOrderFailure("An error occurred while creating the order."));
    }
};
