import { Button, Table } from "reactstrap"
import CommonButton from "../Utils/CommonButton"
import { handleCreateOrderClick } from "./constants/HandleReasonChange"

export const CancelOrPlaceButtons = ({ status, isView, isCancel, subTotalValue, totalAmountFromOrders, taxValue, totalPrice, displayButton, OrderID, orderID, createOrderButton, navigate, setCreateOrderButton, setLoading,createOrCancel,setDisplayButton,state,setOrderID,payload,dispatch,setTaxAmount,taxAmount,setTotalAmountWithTax,totalAmountWithTax, CGST, setCGST, setSGST, SGST, CountryGST, StateGST }) => {
    return (
        <div className=" mt-2">
            {status !== 'Pending' ? (
                <div className="d-flex flex-column align-items-end me-5">
                    <Table className="table-borderless table-nowrap align-middle mb-0" style={{ width: "250px" }}>
                        <tbody>
                            <tr>
                                <td>Sub Total</td>
                                <td className="text-end">{(isView || isCancel) ? subTotalValue?.toFixed(2) : totalAmountFromOrders?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Tax Applied</td>
                                <td className="text-end">{(isView || isCancel) ? taxValue?.toFixed(2) : taxAmount?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="ps-5">SGST</td>
                                <td className="text-end">{(isView || isCancel) ? StateGST?.toFixed(2) : SGST?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="ps-5">CGST</td>
                                <td className="text-end">{(isView || isCancel) ? CountryGST?.toFixed(2) : CGST?.toFixed(2)}</td>
                            </tr>
                            <tr className="border-top border-top-dashed fs-15">
                                <td>Total Amount</td>
                                <td className="text-end">{(isView || isCancel) ? totalPrice?.toFixed(2) : totalAmountWithTax?.toFixed(2)}</td>
                            </tr>
                            {!isView && !isCancel ? (
                                <tr className="border-top border-top-dashed fs-15">
                                    <td colSpan="2" className="text-center">
                                        {!displayButton && (
                                            <>
                                                {OrderID !== "" || orderID !== "" ? (
                                                    <Button className="btn-label mt-1 bg-secondary px-5 text-white w-100" onClick={() => handleCreateOrderClick(orderID, OrderID, setCreateOrderButton, setLoading, navigate, createOrCancel, setDisplayButton, state, setOrderID, payload, dispatch,setTaxAmount,setTotalAmountWithTax,setCGST, setSGST)} disabled={!createOrderButton}>
                                                        Place Order
                                                    </Button>
                                                ) : (
                                                    orderID === "" && (
                                                        <Button className="btn-label mt-1 bg-secondary px-5 text-white w-100" onClick={() => handleCreateOrderClick(orderID, OrderID, setCreateOrderButton, setLoading, navigate, createOrCancel, setDisplayButton, state, setOrderID, payload, dispatch,setTaxAmount,setTotalAmountWithTax, setCGST, setSGST)}>
                                                            Create Order
                                                        </Button>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ) : isCancel ? <tr>
                                <td colSpan="2" className="text-center">
                                    <div className='d-flex justify-content-end gap-2 '>
                                        <CommonButton color={"cancel-button-color"} type={"reset"} buttonAction={() => navigate('/orders')} buttonText={"Cancel"} />
                                        <CommonButton color={"main-color"} type={'submit'} buttonText={"Proceed"} />
                                    </div>
                                </td>
                            </tr> : null}
                        </tbody>
                    </Table>
                </div>
            ) : <div></div>}
        </div>
    )
}