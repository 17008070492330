import { CreateOrder } from "../../../slices/orderManagement/CreateOrder/thunk";
import { placeOrder } from "../../../slices/orderManagement/placeorder/thunk";
import { createOrderPayloaderssss } from "./PatchOrderPayload";

export const handleChangeForReason = (setFieldTouched, setFieldValue, reason, setReason) => (value) => {
    setFieldValue(`${reason}`, value, false);
    setFieldTouched(`${reason}`, false);
    setReason(value)
    setFieldValue(`comment`, "", false)
}

export const closeModal = (setModal, modal) => {
    setModal(!modal);
};

export const toggleModal = (setModal) => {
    setModal((prevModal) => !prevModal);
};

export const handleSaveOrder = (allOrderData, dispatch, setLoading, OrderID, navigate, createOrCancel, setDisplayButton, state, setCreateOrderButton,setTaxAmount,setTotalAmountWithTax,setOrderID) => {
    const payload = createOrderPayloaderssss(allOrderData);
    const placePayload = { state: "Acknowledged" };
    dispatch(placeOrder(setLoading, payload, OrderID, navigate, createOrCancel, setDisplayButton, state, placePayload,setTaxAmount,setTotalAmountWithTax,setOrderID));
    setCreateOrderButton(true);
}

export const handleAddToOrder = ( stateArray, setCreateOrderButton, navigate, CustomerId, orderID) => {
    setCreateOrderButton(false);
    navigate(`/order-filtering/${CustomerId}`, { state: { ...stateArray, orderId: orderID } });
}

export const removeSubscription = (subscriptionID, dispatch) => {
    dispatch({ type: 'REMOVE_ORDER', payload: subscriptionID });
};

export const handleCreateOrderClick = (orderID, OrderID, setCreateOrderButton, setLoading, navigate, createOrCancel, setDisplayButton, state, setOrderID, payload,dispatch,setTaxAmount,setTotalAmountWithTax, setCGST, setSGST) => {
    let orderIdToUse;
    if (orderID) {
        orderIdToUse = orderID;
    } else if (OrderID) {
        orderIdToUse = OrderID;
    }
    if (orderIdToUse) {
        const placePayload = { state: "pending" };
        dispatch(placeOrder(setLoading, placePayload, orderIdToUse, navigate, createOrCancel, setDisplayButton, state, setCGST, setSGST));
    } else {
        dispatch(CreateOrder(payload, setOrderID, state, setTaxAmount,setTotalAmountWithTax, setCGST, setSGST));
        setCreateOrderButton(true);
    }
};