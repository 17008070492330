import { changePlan } from "../../../slices/customer/changeplan/thunk"

export const submitChangePlan = (customerIdPath,selectedOfferCode,subId,immediate,immediateMinus,nextBillCycle,setLoading,dispatch,setCondition,postExpiry,offerCode) => {

    const jsonObj = {
        customerId: customerIdPath,
        offerCode: selectedOfferCode,
        subscriptionId: subId,
        immediate: immediate,
        immediateMinusUsed: immediateMinus,
        postExpiry:postExpiry,
        nextBillCycle: nextBillCycle,
        unSubscribeOfferCode: offerCode
    }
    
    dispatch(changePlan(jsonObj,customerIdPath,setLoading,setCondition))
}