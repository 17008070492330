import moment from "moment";

export const viewOrCancelTableData = (allOfferData, orderDataById, serviceData) => {
    let offerDataOfOrder = [];
    let servName = "";
    let totalPrice = 0;
    let subTotalValue = 0;
    let taxValue = 0;
    let CountryGST = 0 ;
    let StateGST = 0;
    
    allOfferData?.forEach((value) => {
        let serviceFound = false;
        orderDataById?.productOrderItem?.forEach((val) => {
            if (val.productOffering?.id === value.offerCode) {
                serviceData?.forEach((vals) => {
                    if (value.serviceCode === vals.serviceCode) {
                        servName = vals.name;
                        serviceFound = true;
                    }
                });
                
                if (!serviceFound) {
                    servName = "-";
                }
                const dutyFreeAmount = val?.itemTotalPrice?.[0]?.price?.dutyFreeAmount?.value || 0;
                const taxIncludedAmount = val?.itemTotalPrice?.[0]?.price?.taxIncludedAmount?.value || 0;
                const CGST = val?.itemTotalPrice?.[0]?.price?.countryGST || 0;
                const SGST = val?.itemTotalPrice?.[0]?.price?.stateGST || 0;
                subTotalValue += dutyFreeAmount;
                taxValue += taxIncludedAmount - dutyFreeAmount;
                totalPrice += taxIncludedAmount;
                CountryGST += CGST;
                StateGST += SGST;
                offerDataOfOrder.push({
                    subscriptionID: val.id,
                    serviceName: servName,
                    offerName: value.offerName,
                    duration: `${moment(val?.product?.startDate).format("MMM YY, DD")} - ${moment(val?.product?.terminationDate).format("MMM YY, DD")}`,
                    status: orderDataById?.state,
                    unitPrice: dutyFreeAmount,
                    subTotal: dutyFreeAmount,
                });
            }
        });
    });
    return { offerDataOfOrder, servName, taxValue, subTotalValue, totalPrice , CountryGST, StateGST};
};